<template>
  <div
    id="error"
    class="flex justify-center items-center h-85vh"
  >
    <div class="space-y-4">
      <h1 class="text-8xl text-nasida-green font-bold">404</h1>
      <p>The page you are looking for does not exist</p>
      <AnchorLink url="/" text="Go Home" class="mx-auto" />
    </div>
  </div>
</template>

<script src="./error.page.js"></script>
<style src="./error.page.css" scoped lang="css"></style>